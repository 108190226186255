.country-name {
  font-weight: 600; /* SemiBold */
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .country-name {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
