@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply the Hanken Grotesk font to the whole body */
body {
  @apply font-sans;
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* **************** Pop-up ***************** */

@keyframes popupSlideUpBottom {
  from {
    bottom: 0;
  }
  to {
    bottom: 50%;
  }
}

@keyframes popupSlideDownBottom {
  from {
    bottom: 50%;
  }
  to {
    bottom: 0;
  }
}

.animate-popupSlideUpBottom--default {
  animation: popupSlideUpBottom 0.2s linear forwards;
}

.animate-popupSlideDownBottom--default {
  animation: popupSlideDownBottom 0.2s linear forwards;
}

@keyframes popupSlideUpTop {
  from {
    top: 100%; /* Start from below the screen */
  }
  to {
    top: 20px; /* Animate to target top position */
  }
}

@keyframes popupSlideDownTop {
  from {
    top: 20px; /* Start from the target top position */
  }
  to {
    top: 100%; /* Animate back to below the screen */
  }
}

.animate-popupSlideUpTop--custom {
  animation: popupSlideUpTop 0.2s linear forwards;
}

.animate-popupSlideDownTop--custom {
  animation: popupSlideDownTop 0.2s linear forwards;
}

/* Apply font to the date picker component */
.react-datepicker,
.react-datepicker__input-container,
.react-datepicker__day,
.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__day-name {
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 400; /* Adjust weight as needed */
}

/* *******************Spinner with overlay*********************** */
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's above other elements */
}

/*********************Toast animation*********************** */
@keyframes toastAnimation {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    bottom: 1rem;
    opacity: 1;
  }
}

.animate-toast {
  animation: toastAnimation 0.5s ease-out forwards;
}
