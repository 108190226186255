.booking-step-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 768px) {
  /* Mobile screens (smaller than md) */
  .booking-step-title {
    font-size: 16px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

/* DatePickerCustom.css */

/* General Styles */
.react-datepicker {
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
.react-datepicker__header {
  /* background-color: #7973f0; */
  color: white;
  border-bottom: none;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

/* Day Button Styles */
.react-datepicker__day,
.react-datepicker__day--selected {
  width: 40px;
  line-height: 40px;
  margin: 5px;
  border-radius: 50%;
  color: #1a202c;
}

/* .react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #4f46e5;
  color: #fff;
} */

/* .react-datepicker__day:hover {
  background-color: #d4d4ff;
} */

/* Current Day */
.react-datepicker__day--today {
  font-weight: inherit;
  /* color: #1a202c; */
}

/* Custom Current Day */
.react-datepicker__day--minday {
  font-weight: bold;
  color: #1a202c;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

/* TODO: Change font-family */

/* ================ Stripe Modal ==================== */
.stripeModal {
  position: relative;
  width: 50%;
  padding-top: 0px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .stripeModal {
    width: 95%;
  }
}
